define("photos/instance-initializers/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(app) {
    app.register('assets:asset-map', app.lookup('controller:asset-map'), {
      instantiate: false,
      singleton: true
    });
    app.inject('route', 'assetMap', 'assets:asset-map');
    app.inject('controller', 'assetMap', 'assets:asset-map');
    app.inject('component', 'assetMap', 'assets:asset-map');
  }

  var _default = {
    name: 'asset-map',
    initialize: initialize
  };
  _exports.default = _default;
});