define("photos/routes/index", ["exports", "photos/image-manifest"], function (_exports, _imageManifest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return {
        photos: _imageManifest.default.exifData.sortBy('FileName')
      };
    }
  });

  _exports.default = _default;
});