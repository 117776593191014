define("photos/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vreAwIGU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"flex flex-column flex-auto justify-center items-center f4 ph3 lh-copy theme-color theme-bg\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"overflow-y-scroll\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mw6 w-100 tc\"],[9],[0,\"Sorry, that page doesn't exist. Please try one of the links in the menu instead.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "photos/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});