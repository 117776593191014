define("photos/router", ["exports", "photos/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var locationType = _environment.default.locationType,
      rootURL = _environment.default.rootURL;
  var Router = Ember.Router.extend({
    location: locationType,
    rootURL: rootURL,
    metrics: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var page = document.location.pathname;
          var title = Ember.get(_this, 'currentRouteName') || 'unknown';
          Ember.get(_this, 'metrics').trackPage({
            page: page,
            title: title
          });
        });
      }
    }
  });
  Router.map(function () {
    this.route('info');
    this.route('not-found', {
      path: '*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});