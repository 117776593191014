define("photos/templates/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/7tlxMLx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"flex flex-column flex-auto justify-center items-center f4 ph3 lh-copy\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"overflow-y-scroll overflow-scroll-touch\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mw6 w-100\"],[9],[0,\"Hi, I'm Jeff, a Software Engineer from Toronto, Canada, currently living and working in the United Kingdom.\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mw6 w-100\"],[9],[0,\"I've been taking photographs for several years as a hobby, with the odd commissioned project. I haven't had a central place to show off my favourites for several years. I wanted something simple and minimal, and this is the first iteration of that goal.\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mw6 w-100\"],[9],[0,\"You can follow me on \"],[7,\"a\"],[11,\"href\",\"https://instagram.com/jeffjewiss\"],[11,\"class\",\"dark-green link hover-green hover-underline\"],[9],[0,\"Instagram\"],[10],[0,\" or read a bit more \"],[7,\"a\"],[11,\"href\",\"https://jeffjewiss.com\"],[11,\"class\",\"dark-green link hover-green hover-underline\"],[9],[0,\"about me\"],[10],[0,\".\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mw6 w-100\"],[9],[0,\"If you'd like to get in touch, you can reach me at \"],[7,\"a\"],[11,\"class\",\"blue link hover-dark-blue hover-underline\"],[11,\"href\",\"mailto:photography@jeffjewiss.com\"],[9],[0,\"photography@jeffjewiss.com\"],[10],[0,\".\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "photos/templates/info.hbs"
    }
  });

  _exports.default = _default;
});