define("photos/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FKA0aYJQ",
    "block": "{\"symbols\":[\"photo\",\"index\"],\"statements\":[[7,\"section\"],[11,\"class\",\"dt-row overflow-x-scroll overflow-scroll-touch items-baseline pl3 flex-auto theme-bg-photos\"],[11,\"data-photo-scroll\",\"\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"photos\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"portfolio-photo\",null,[[\"filename\",\"title\",\"description\",\"location\",\"aperture\",\"shutterspeed\",\"focalLength\",\"iso\",\"index\",\"class\"],[[22,1,[\"FileName\"]],[22,1,[\"Title\"]],[22,1,[\"Description\"]],[22,1,[\"Location\"]],[22,1,[\"Aperture\"]],[22,1,[\"ShutterSpeed\"]],[22,1,[\"FocalLength\"]],[22,1,[\"ISO\"]],[22,2,[]],[27,\"aspect-ratio\",[[22,1,[\"ImageWidth\"]],[22,1,[\"ImageHeight\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "photos/templates/index.hbs"
    }
  });

  _exports.default = _default;
});