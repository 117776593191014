define("photos/controllers/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    AssetMapController
    This will be injected into all your components, controllers, and routes.
  
    @module ember-cli-inject-asset-map
    @class AssetMapController
    @extends Ember.Controller
  */
  var _default = Ember.Controller.extend({
    /**
      @attribute assetMapHash
      @type {Object}
    */
    assetMapHash: undefined,

    /**
      @method pathForAsset
      @type {String|undefined}
    */
    pathForAsset: function pathForAsset(key) {
      var key = key.replace(/^\//, '');
      var asset = this.get('assetMapHash')[key];

      if (asset) {
        return '/'.concat(asset);
      } else {
        return undefined;
      }
    }
  });

  _exports.default = _default;
});