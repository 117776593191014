define("photos/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customProperties: Ember.inject.service(),
    customPropertiesSupported: false,
    isLightTheme: true,
    isDarkTheme: Ember.computed.not('isLightTheme'),
    application: Ember.computed.alias('model'),
    actions: {
      toggleDarkTheme: function toggleDarkTheme() {
        var isLight = Ember.get(this, 'isLightTheme');

        if (isLight) {
          this.enableNightTheme();
        } else {
          this.enableDayTheme();
        }

        this.toggleProperty('isLightTheme');
      }
    },
    init: function init() {
      if (Ember.get(this, 'customProperties.supported')()) {
        Ember.set(this, 'customPropertiesSupported', true);
      }

      if (isNight()) {
        this.enableNightTheme();
        Ember.set(this, 'isLightTheme', false);
      }

      this._super.apply(this, arguments);
    },
    enableNightTheme: function enableNightTheme() {
      Ember.get(this, 'customProperties').setVal({
        variableName: '--theme-bg',
        variableValue: '#111'
      });
      Ember.get(this, 'customProperties').setVal({
        variableName: '--theme-bg-photos',
        variableValue: '#222'
      });
      Ember.get(this, 'customProperties').setVal({
        variableName: '--theme-color',
        variableValue: '#eee'
      });
      Ember.get(this, 'customProperties').setVal({
        variableName: '--theme-hover-color',
        variableValue: '#fff'
      });
      Ember.get(this, 'customProperties').setVal({
        variableName: '--theme-b--edge',
        variableValue: '#222'
      });
    },
    enableDayTheme: function enableDayTheme() {
      var _this = this;

      ['--theme-bg', '--theme-bg-photos', '--theme-color', '--theme-hover-color', '--theme-b--edge'].forEach(function (property) {
        return Ember.get(_this, 'customProperties').removeVal({
          variableName: property
        });
      });
    }
  });

  _exports.default = _default;

  function isNight() {
    var hour = new Date().getHours();
    return hour < 8 || hour > 19;
  }
});