define("photos/image-manifest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    exifData: [{
      "FileName": "05-Fjaðrárgljúfur.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 8,
      "ShutterSpeed": "1/210",
      "FocalLength": "35.0 mm",
      "ISO": 400,
      "Title": "Canyon Perspective",
      "Location": "Fjaðrárgljúfur",
      "Label": "travel"
    }, {
      "FileName": "15-Icefields-Parkway.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1334,
      "Description": "Made a stop along the Icefields Parkway to see some waterfalls and found this view on the walk to the lookout.",
      "Aperture": 8,
      "ShutterSpeed": "1/110",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "In Search of Waterfalls",
      "Location": "Icefields Parkway",
      "Label": "travel"
    }, {
      "FileName": "08-Sintra.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1334,
      "Aperture": 5.6,
      "ShutterSpeed": "1/480",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "Castle Views",
      "Location": "Sintra",
      "Label": "travel"
    }, {
      "FileName": "11-Oregon.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1334,
      "Aperture": 5.6,
      "ShutterSpeed": "1/1500",
      "FocalLength": "27.0 mm",
      "ISO": 400,
      "Title": "Cannon Beach",
      "Location": "Oregon",
      "Label": "travel"
    }, {
      "FileName": "14-Jasper.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1334,
      "Description": "Hiking around Jasper and stopping to take in the surroundings.",
      "Aperture": 8,
      "ShutterSpeed": "1/400",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "A Walk in the Mountains",
      "Location": "Jasper",
      "Label": "travel"
    }, {
      "FileName": "06-Iceland.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 11,
      "ShutterSpeed": "1/450",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "All This",
      "Location": "Iceland",
      "Label": "travel"
    }, {
      "FileName": "12-Alberta.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1334,
      "Description": "Took a break from driving on our way through Alberta.",
      "Aperture": 10,
      "ShutterSpeed": "1/160",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "Glacial Views",
      "Location": "Alberta",
      "Label": "travel"
    }, {
      "FileName": "01-Iceland.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 11,
      "ShutterSpeed": "1/250",
      "FocalLength": "35.0 mm",
      "ISO": 400,
      "Title": "Mountain Coast",
      "Location": "Iceland",
      "Label": "travel"
    }, {
      "FileName": "13-Reykjanes.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 11,
      "ShutterSpeed": "1/240",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "Continental Divide",
      "Location": "Reykjanes",
      "Label": "travel"
    }, {
      "FileName": "10-Paris.jpg",
      "ImageWidth": 1333,
      "ImageHeight": 2000,
      "Aperture": 8,
      "ShutterSpeed": "1/250",
      "FocalLength": "35.0 mm",
      "ISO": 800,
      "Title": "Metro Views",
      "Location": "Paris",
      "Label": "travel"
    }, {
      "FileName": "00-Sahara.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 5.6,
      "ShutterSpeed": "1/750",
      "FocalLength": "35.0 mm",
      "ISO": 400,
      "Title": "Caravan",
      "Location": "Sahara",
      "Label": "travel"
    }, {
      "FileName": "04-Iceland.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 8,
      "ShutterSpeed": "1/400",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "Mountain Village",
      "Location": "Iceland",
      "Label": "travel"
    }, {
      "FileName": "09-Cannes.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 11,
      "ShutterSpeed": "1/600",
      "FocalLength": "35.0 mm",
      "ISO": 800,
      "Title": "Golden",
      "Location": "Cannes",
      "Label": "travel"
    }, {
      "FileName": "07-Iceland.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 11,
      "ShutterSpeed": "1/200",
      "FocalLength": "35.0 mm",
      "ISO": 200,
      "Title": "Hot Spring",
      "Location": "Iceland",
      "Label": "travel"
    }, {
      "FileName": "02-Iceland.jpg",
      "ImageWidth": 2000,
      "ImageHeight": 1333,
      "Aperture": 7.1,
      "ShutterSpeed": "1/220",
      "FocalLength": "35.0 mm",
      "ISO": 1000,
      "Title": "Crater Lake",
      "Location": "Iceland",
      "Label": "travel"
    }, {
      "FileName": "03-Fjaðrárgljúfur.jpg",
      "ImageWidth": 1333,
      "ImageHeight": 2000,
      "Aperture": 8,
      "ShutterSpeed": "1/100",
      "FocalLength": "35.0 mm",
      "ISO": 400,
      "Title": "9000 Years",
      "Location": "Fjaðrárgljúfur",
      "Label": "travel"
    }]
  };
  _exports.default = _default;
});