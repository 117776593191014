define("photos/components/portfolio-photo/component", ["exports", "ember-in-viewport", "photos/config/environment"], function (_exports, _emberInViewport, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var staticHost = _environment.default.staticHost,
      environment = _environment.default.environment;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    fastboot: Ember.inject.service(),
    tagName: 'figure',
    classNames: ['dtc', 'h-100', 'w-auto', 'v-top', 'pr3'],
    placeholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    isShowingPhoto: false,
    isPhotoLoaded: Ember.computed.not('isPhotoLoading'),
    isShowingPlaceholder: Ember.computed.not('isShowingPhoto'),
    isClient: Ember.computed.not('fastboot.isFastBoot'),
    isFirst: Ember.computed.equal('index', 0),
    photoUrl: Ember.computed('filename', function () {
      var filepath = "assets/photos/".concat(Ember.get(this, 'filename'));
      var assetMap = Ember.get(this, 'assetMap.assetMapHash');
      var isProduction = environment === 'production';

      if (isProduction) {
        return "".concat(staticHost).concat(assetMap[filepath]);
      } else {
        return "/".concat(filepath);
      }
    }),
    photoSource: Ember.computed('isShowingPhoto', function () {
      if (Ember.get(this, 'isShowingPhoto')) {
        return Ember.get(this, 'photoUrl');
      } else {
        return Ember.get(this, 'placeholder');
      }
    }),
    photoAlt: Ember.computed('title', 'location', 'description', function () {
      var title = Ember.get(this, 'title');
      var location = Ember.get(this, 'location');
      var description = Ember.get(this, 'description');
      description = description ? " \u2013 ".concat(description) : '';
      if (!title) return '';
      return "".concat(title, " (").concat(location, ")").concat(description);
    }),
    loadingOpacity: Ember.computed('isShowingPhoto', 'isPhotoLoading', function () {
      if (Ember.get(this, 'isShowingPhoto')) {
        return 'o-100';
      } else if (Ember.get(this, 'isPhotoLoading')) {
        return 'o-30';
      } else {
        return 'o-10';
      }
    }),
    init: function init() {
      this._super();

      if (Ember.get(this, 'isClient')) {
        Ember.set(this, 'imageLoader', new Image());
      }
    },
    willDestroyElement: function willDestroyElement() {
      var img = Ember.get(this, 'imageLoader');

      if (img) {
        img = img.onload = img.onerror = null;
        Ember.set(this, 'imageLoader', null);
      }
    },
    didEnterViewport: function didEnterViewport() {
      Ember.run.scheduleOnce('afterRender', this, this.loadImage);
    },
    loadImage: function loadImage() {
      var component = this;
      var photoUrl = Ember.get(this, 'photoUrl');

      if (photoUrl) {
        var img = Ember.get(this, 'imageLoader');

        if (img) {
          this.trigger('willLoad', photoUrl);
          Ember.setProperties(component, {
            isPhotoLoading: true,
            isPhotoError: false
          });

          img.onload = function (e) {
            Ember.run(function () {
              Ember.setProperties(component, {
                isPhotoLoading: false,
                isPhotoError: false,
                isShowingPhoto: true
              });
              component.trigger('didLoad', img, e);
            });
          };

          img.onerror = function (e) {
            Ember.run(function () {
              Ember.setProperties(component, {
                isPhotoLoading: false,
                isPhotoError: true
              });
              component.trigger('becameError', img, e);
            });
          };

          img.src = photoUrl;
        }
      }
    }
  });

  _exports.default = _default;
});